var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.triggerTableHeaders,"items":_vm.triggers,"item-key":"name","no-data-text":_vm.$t('table_empty'),"footer-props":{
        'items-per-page-options': [20],
        'disableItemsPerPage': false,
         pageText: _vm.$t('table_footer.page_text'),
    }},on:{"click:row":_vm.handleClickByTriggerRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('trigger_table.toolbar_title')))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),(_vm.isOperator && _vm.isLicenseValid)?_c('v-btn',{staticClass:"mb-2 primary--text",on:{"click":function($event){return _vm.openAddTriggerDialog()}}},[_vm._v(" "+_vm._s(_vm.$t('trigger_table.add_trigger_button'))+" ")]):_vm._e()],1)]},proxy:true},{key:"item.check",fn:function(ref){
    var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.collorTriggerCheck(item),"small":""}},[_vm._v(" mdi-circle ")])]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [(item.actions.length > 0)?_c('div',[_vm._v(" "+_vm._s(item.actions[0].name)+" "),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openActionDeleteDialog(item)}}},[_vm._v(" mdi-delete ")])],1):_c('div',[_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.openAddActionDialog(item)}}},[_vm._v(" mdi-plus-outline ")]),_vm._v(" "+_vm._s(_vm.$t('trigger_table.add_action_button'))+" ")],1)]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogTrigger),callback:function ($$v) {_vm.dialogTrigger=$$v},expression:"dialogTrigger"}},[_c('trigger-form',{attrs:{"trigger":_vm.trigger},on:{"cancel":_vm.closeTriggerDialog,"confirm":_vm.closeTriggerDialog}})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogAddAction),callback:function ($$v) {_vm.dialogAddAction=$$v},expression:"dialogAddAction"}},[_c('action-form',{on:{"cancel":_vm.closeAddActionDialog,"save":_vm.saveAction}})],1),_c('dialog-delete',{on:{"confirm":_vm.deleteActionConfirm},model:{value:(_vm.dialogActionDelete),callback:function ($$v) {_vm.dialogActionDelete=$$v},expression:"dialogActionDelete"}},[_vm._v(" "+_vm._s(_vm.$t('trigger_table.delete_action_dialog'))+" "+_vm._s(_vm.deletedAction.name)+"? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }