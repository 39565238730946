<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">{{ cardTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="formAddAction"
          v-model="isValidActionForm"
        >
          <v-text-field
            v-model="editedActionItem.name"
            :label="$t('action_form.name')"
            :rules="[rules.required, uniqActionName]"
          ></v-text-field>
          <v-select
            v-model="editedActionItem.action_type"
            :items="actionTypes"
            :label="$t('action_form.action_type_label')"
            :rules="[rules.required]"
            @change="changeActionParams"
          ></v-select>
          <div v-if="editedActionItem.action_type == 'play'">
            <v-radio-group
              v-model="editedActionItem.params.entity_type"
              row
            >
              <v-radio
                v-for="(value, index) in playingTypes"
                :key="index"
                :label="value.text"
                :value="value.value"
              ></v-radio>
            </v-radio-group>
            <div v-if="editedActionItem.params.entity_type == 'cue'">
              <v-select
                v-model="editedActionItem.params.entity_id"
                :items="cues"
                :label="$t('action_form.cue_label')"
                item-text="filename"
                item-value="id"
                :rules="[rules.required]"
              ></v-select>
            </div>
            <div v-else-if="editedActionItem.params.entity_type == 'playlist'">
              <v-select
                v-model="editedActionItem.params.entity_id"
                :items="playlists"
                :label="$t('action_form.playlist_label')"
                item-text="name"
                item-value="id"
                :rules="[rules.required]"
              ></v-select>
            </div>
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="mb-2 primary--text"
          @click="cancel"
        >
          {{ $t('action_form.cancel_button') }}
        </v-btn>
        <v-btn
          :disabled="!isValidActionForm"
          class="mb-2 primary--text"
          @click="save">
          {{ $t('action_form.save_button') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>

</template>

<script>

export default {
  name: 'ActionForm',
  props: [
  ],
  data: () => ({
    isValidActionForm: true,

    defaultPlayActionParams: {
      entity_type: 'cue',
      entity_id: 0,
    },
    defaultStopActionParams: {},
    defaultPauseActionParams: {},

    editedActionItem: {
      name: '',
      action_type: '',
      params: {},
    },
    defaultActionItem: {
      name: '',
      action_type: '',
      params: {},
    },
  }),
  computed: {
    actionTypes() {
      return [
        { text: this.$t('action_form.action_types.play'), value: 'play' },
        { text: this.$t('action_form.action_types.stop'), value: 'stop' },
        { text: this.$t('action_form.action_types.pause'), value: 'pause' },
      ]
    },
    playingTypes() {
      return [
        { text: this.$t('action_form.playing_types.cue'), value: 'cue' },
        { text: this.$t('action_form.playing_types.playlist'), value: 'playlist' },
      ]
    },
    rules() {
      return {
        required: value => !!value || this.$t('action_form.required'),
      }
    },
    isEdit() {
      return false
    },
    cardTitle() {
      return this.isEdit ? '' : this.$t('action_form.title')
    },
    cues() {
      return this.$store.state.cues;
    },
    playlists() {
      return this.$store.state.playlists;
    },
    triggers() {
        return this.$store.state.trigger['triggersWithActions']
    },
  },
  methods: {
    uniqActionName(value) {
      // FIXME: Переделать на запрос списка actions с бекенда и проверку по нему. То как реализованно сейчас имеет большую сложность.
      if (value == 'default') { return this.$t('action_form.action_must_be_unique') }
      for (let trigger of this.triggers) {
        for (let action of trigger.actions)
          if (action.name == value) { return this.$t('action_form.action_must_be_unique') }
      }
      return true
    },
    changeActionParams (action_type) {
      if (action_type === 'play') {
        this.editedActionItem.params = Object.assign({}, this.defaultPlayActionParams)
      } else if (action_type === 'stop') {
        this.editedActionItem.params = Object.assign({}, this.defaultStopActionParams)
      } else if (action_type === 'pause') {
        this.editedActionItem.params = Object.assign({}, this.defaultPauseActionParams)
      }
    },
    save() {
      this.$emit('save', this.editedActionItem)
      this.$refs.formAddAction.resetValidation()
    },
    cancel() {
      this.$emit('cancel')
      this.$refs.formAddAction.resetValidation()
    }
  },
  created() {
    this.$store.dispatch("getPlaylists");
    this.$store.dispatch("getCues");
  }
}
</script>

<style>

</style>