<template>
  <div>
    <v-card>
      <v-card-title>
        <span class="headline">{{cardTitle}}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          ref="formTrigger"
          v-model="isValidTriggerForm"
          :readonly = "isEdit"
        >
          <v-text-field
            v-model="editedTriggerItem.name"
            :label="$t('trigger_form.name')"
            :rules="isEdit ? [] : [rules.required, uniqTriggerName]"
          ></v-text-field>
          <v-select
            v-model="editedTriggerItem.tr_type"
            :items="triggerTypes"
            :label="$t('trigger_form.trigger_type')"
            :rules="[rules.required]"
            @change="changeTriggerParams"
          ></v-select>
          <div v-if="editedTriggerItem.tr_type == 'ArtNet'">
            <v-text-field
              v-model="editedTriggerItem.params.listen_port"
              :label="$t('trigger_form.listen_port')"
              :rules="[rules.portRange, rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="editedTriggerItem.params.universe"
              :label="$t('trigger_form.universe')"
              :rules="[rules.uniRange, rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="editedTriggerItem.params.channel"
              :label="$t('trigger_form.channel')"
              :rules="[rules.channelRange, rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="editedTriggerItem.params.min_level"
              :label="$t('trigger_form.min_level')"
              :rules="[rules.channelLevelRange, rules.required]"
            ></v-text-field>
            <v-text-field
              v-model="editedTriggerItem.params.max_level"
              :label="$t('trigger_form.max_level')"
              :rules="[rules.channelLevelRange, rules.required]"
            ></v-text-field>
          </div>
          <div v-else-if="editedTriggerItem.tr_type == 'RawUDP'">
            <v-text-field
              v-model="editedTriggerItem.params.listen_port"
              :label="$t('trigger_form.listen_port')"
              :rules="[rules.portRange, rules.required]"
            ></v-text-field>
            <v-textarea
              v-model="editedTriggerItem.params.data"
              :label="$t('trigger_form.data')"
              outlined
            ></v-textarea>
          </div>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="mb-2 primary--text"
          @click="cancel"
        >
          {{ $t('trigger_form.cancel_button') }}
        </v-btn>
        <v-btn v-if="isEdit"
          class="mb-2 primary--text"
          @click="openDeleteTriggerDialog">
          {{ $t('trigger_form.delete_button') }}
        </v-btn>
        <v-btn v-else
          :disabled="!isValidTriggerForm"
          class="mb-2 primary--text"
          @click="save">
          {{ $t('trigger_form.save_button') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <dialog-delete v-model="dialogTriggerDelete" @confirm="deleteTriggerConfirm">
      {{ $t('trigger_form.delete_trigger_dialog') }} {{ editedTriggerItem.name }}?
    </dialog-delete>
  </div>
</template>
  
<script>
  import DialogDelete from '../DialogDelete.vue'

  export default {
    name: 'TriggerDialog',
    props: [
      'trigger',
    ],
    components: {
      DialogDelete,
    },
    data: () => ({
  
      dialogTriggerDelete: false,

      isValidTriggerForm: true,
  
      editedTriggerItem: {
        name: '',
        tr_type: '',
        params: {},
        actions: [],
      },
      defaultTriggerItem: {
        name: '',
        tr_type: '',
        params: {},
        actions: [],
      },
      defaultArtNetTriggerParams: {
        network_type: "udp",
        listen_ip: "0.0.0.0",
        listen_port: "6454",
        universe: "1",
        channel: "1",
        min_level: "1",
        max_level: "255",
      },
      defaultRawUdpTriggerParams: {
        network_type: "udp",
        listen_ip: "0.0.0.0",
        listen_port: "1025",
        data: "",
      },
      triggerTypes: [
        'ArtNet',
        'RawUDP',
      ],
    }),
    computed: {
      rules() {
        return {
          required: value => !!value || this.$t('trigger_form.rules.required'),
          portRange: value => parseInt(value) < 65535 && parseInt(value) > 0 || this.$t('trigger_form.rules.port_range'),
          ip: value => {
            const pattern = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
            return pattern.test(value) || this.$t('trigger_form.rules.invalid_ip')
          },
          uniRange: value => parseInt(value) <= 256 && parseInt(value) >= 0 || this.$t('trigger_form.rules.uni_range'),
          channelLevelRange: value => parseInt(value) <= 255 && parseInt(value) >= 0 || this.$t('trigger_form.rules.channel_level_range'),
          channelRange: value => parseInt(value) <= 512 && parseInt(value) >= 1 || this.$t('trigger_form.rules.channel_range'),
          }
      },
      cardTitle() {
        return this.isEdit ? '' : this.$t('trigger_form.card_title')
      },
      isEdit () {
        return Object.keys(this.trigger).length !== 0
      },
      triggers() {
        return this.$store.state.trigger['triggersWithActions']
      },
    },
    methods: {
      uniqTriggerName(value) {
        for (let trigger of this.triggers) {
          if (trigger.name == value) { return this.$t('trigger_form.unique_trigger') }
        }
        return true
      },
      changeTriggerParams (tr_type) {
        if (tr_type === 'ArtNet') {
          this.editedTriggerItem.params = Object.assign({}, this.defaultArtNetTriggerParams)
        } else if (tr_type === 'RawUDP') {
          this.editedTriggerItem.params = Object.assign({}, this.defaultRawUdpTriggerParams)
        }
      },
      cancel() {
        this.$nextTick(() => {
          this.editedTriggerItem = Object.assign({}, this.defaultTriggerItem)
        })
        this.$refs.formTrigger.resetValidation()
        this.$emit('cancel')
      },
      save() {
        this.$store.dispatch("trigger/createTrigger", this.editedTriggerItem)
        this.$emit('confirm', this.editedTriggerItem)
        this.$refs.formTrigger.resetValidation()
      },

      openDeleteTriggerDialog() {
        this.dialogTriggerDelete = true
      },
      deleteTriggerConfirm() {
        this.$store.dispatch("trigger/deleteTrigger", this.editedTriggerItem)
        this.$emit('cancel')
      },
    },
    updated() {
      if (this.isEdit) {
        this.editedTriggerItem = this.trigger
      }
    },
  }
  </script>