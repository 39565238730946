<template>
  <div>
    <div>
      <v-data-table
        :headers="triggerTableHeaders"
        :items="triggers"
        item-key="name"
        :no-data-text="$t('table_empty')"
        :footer-props="{
          'items-per-page-options': [20],
          'disableItemsPerPage': false,
           pageText: $t('table_footer.page_text'),
      }"
        class="elevation-1"
        @click:row="handleClickByTriggerRow"
      >
        <template v-slot:top> 
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('trigger_table.toolbar_title') }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
              <v-btn
                v-if="isOperator && isLicenseValid"
                class="mb-2 primary--text"
                @click="openAddTriggerDialog()"
              >
                {{ $t('trigger_table.add_trigger_button') }}
              </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.check="{ item }">
          <v-icon :color="collorTriggerCheck(item)" small>
            mdi-circle
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <div
            v-if="item.actions.length > 0"
          >
            {{ item.actions[0].name }}
            <v-icon small @click.stop="openActionDeleteDialog(item)">
              mdi-delete
            </v-icon>
          </div>
          <div
            v-else
          >
            <v-icon @click.stop="openAddActionDialog(item)">
              mdi-plus-outline
            </v-icon>
            {{ $t('trigger_table.add_action_button') }}
          </div>
        </template>
      </v-data-table>
    </div>
    <v-dialog v-model="dialogTrigger" max-width="500px">
      <trigger-form :trigger="trigger" @cancel="closeTriggerDialog" @confirm="closeTriggerDialog"></trigger-form>
    </v-dialog>
    <v-dialog v-model="dialogAddAction" max-width="500px">
      <action-form @cancel="closeAddActionDialog" @save="saveAction"></action-form>
    </v-dialog>
    <dialog-delete v-model="dialogActionDelete" @confirm="deleteActionConfirm">
      {{ $t('trigger_table.delete_action_dialog') }} {{ deletedAction.name }}?
    </dialog-delete>
  </div>
</template>

<script>
  import TriggerForm from './TriggerForm.vue'
  import ActionForm from './ActionForm.vue'
  import DialogDelete from '../DialogDelete.vue'

export default {
  name: 'Triggers',
  components: {
    TriggerForm,
    ActionForm,
    DialogDelete,
  },
  data: () => ({
    
    dialogTrigger: false,
    dialogAddAction: false,
    dialogActionDelete: false,

    trigger: {},
    deletedAction: {},
    triggerForAction: {},

    activatedTriggers: [],
  }),
  computed: {
    triggerTableHeaders() {
      return [
        { text: this.$t('trigger_table.headers.check'), value: 'check', width: '90px'},
        { text: this.$t('trigger_table.headers.name'), value: 'name' },
        { text: this.$t('trigger_table.headers.type'), value: 'tr_type' },
        { text: this.$t('trigger_table.headers.action'), value: 'actions' },
      ]
    },
    isOperator() {
      return this.$store.getters.isOperator
    },
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    triggers() {
      return this.$store.state.trigger['triggersWithActions']
    },
  },
  methods: {
    collorTriggerCheck(item) {
      if (this.activatedTriggers.indexOf(item.name) != -1) {
        return 'rgb(8, 253, 8)'
      } else {
        return 'rgb(221, 230, 221)'
      }
    },
    openAddTriggerDialog () {
      this.trigger = {}
      this.dialogTrigger = true
    },

    closeTriggerDialog() {
      this.trigger = {}
      this.dialogTrigger = false
    },

    handleClickByTriggerRow (value) {
      this.dialogTrigger = true
      this.trigger = Object.assign({}, value)
    },

    openAddActionDialog(trigger) {
      this.triggerForAction = trigger
      this.dialogAddAction = true
    },
    closeAddActionDialog () {
      this.$nextTick(() => {
        this.triggerForAction = {}
      })
      this.dialogAddAction = false
    },
    saveAction (action) {
      let relation = {
        trigger: this.triggerForAction,
        action: action,
      }
      this.$store.dispatch("trigger/createActionRelatedTrigger", relation)
      this.closeAddActionDialog()
    },
    openActionDeleteDialog (trigger) {
      this.triggerForAction = trigger
      this.dialogActionDelete = true
    },
    closeActionDeleteDialog () {
      this.$nextTick(() => {
        this.triggerForAction = {}
      })
      this.dialogActionDelete = false
    },
    deleteActionConfirm () {
      this.$store.dispatch("trigger/deleteAction", this.triggerForAction.actions[0])
      this.closeActionDeleteDialog()
    },

    connectToTriggerActivatedWebsocket() {
      // Подключает к вебсокету для получения информации о сработавших триггерах.
      //
      this.$store.dispatch('connectToWebsocket', 'ws/trigger/trigger_activated_info').then( (wsConn) => {
        this.ws_connection_to_trigger_activated_info = wsConn
        this.ws_connection_to_trigger_activated_info.onmessage = (event) => {
          event.data.text().then(text => {
            let triggerActiv = JSON.parse(text)
            this.activatedTriggers.push(triggerActiv.trigger.name)
            setTimeout(
              () => {
                let index = this.activatedTriggers.indexOf(triggerActiv.trigger.name)
                this.activatedTriggers.splice(index)
              },
              1000
            )
          })
        }
      })
    },
  },
  created() {
    this.$store.dispatch("trigger/getAllTriggersWithRelatedActions");
  },
  mounted() {
    this.connectToTriggerActivatedWebsocket()
  },
  destroyed() {
    this.ws_connection_to_trigger_activated_info.close()
  },
}
</script>