<template>
  <div>
    <p class="title text-center">{{ $t('triggers.title') }}</p>
    <triggers-table />
  </div>
  
</template>

<script>
import triggersTable from '@/components/TriggerComponents/TriggerTable.vue'

export default {
  name: 'trigger_view',
  components: {triggersTable},
}
</script>

<style>

</style>